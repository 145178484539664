<template>
  <DefaultLayout>
    <v-layout>
      <v-flex>
        <v-card elevation="5" class="ma-5">
          <v-card-title>
            {{ $t("releaseNotes.pageTitle") }}
          </v-card-title>
          <v-expansion-panels focusable v-model="panel">
            <v-expansion-panel v-for="(item, i) in rl" :key="i">
              <v-expansion-panel-header>{{ item.version }} - {{ item.title }}</v-expansion-panel-header>
              <v-expansion-panel-content class="ma-5">
                <h3 v-if="item.features.length" v-html="$t('releaseNotes.list.features')"></h3>
                <ul>
                  <li v-for="(f, j) in item.features" :key="j">{{ f }}</li>
                </ul>
                <h3 v-if="item.bugfixes.length" class="mt-5" v-html="$t('releaseNotes.list.bugfixes')"></h3>
                <ul>
                  <li v-for="(f, j) in item.bugfixes" :key="j">{{ f }}</li>
                </ul>
                <h3 v-if="item.others.length" class="mt-5" v-html="$t('releaseNotes.list.others')"></h3>
                <ul>
                  <li v-for="(f, j) in item.others" :key="j">{{ f }}</li>
                </ul>
                <v-btn style="float: right" text color="silver" @click="user.profile.seenVersion = item.num">DEBUG: Set seen Verison to {{ item.num }}</v-btn>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card>
      </v-flex>
    </v-layout>

    <v-dialog v-model="dlg" max-width="700px">
      <v-card>
        <v-card-title color="primary" v-html="$t('releaseNotes.popup.title')"></v-card-title>
        <v-alert type="success" class="mx-5"> {{ crl.version }} - {{ crl.title }} </v-alert>
        <v-card-text>
          <h3 v-if="crl.features.length" v-html="$t('releaseNotes.list.features')"></h3>
          <ul>
            <li v-for="(f, j) in crl.features" :key="j">{{ f }}</li>
          </ul>
        </v-card-text>

        <v-card-actions v-if="$route.query.returnurl" class="ma-5">
          <v-btn color="primary" text @click="dlg = false">
            {{ $t("releaseNotes.popup.read") }}
          </v-btn>
          <v-divider />
          <v-btn color="primary" @click="goToReturnUrl()">
            {{ $t("releaseNotes.popup.continue") }}
          </v-btn>
        </v-card-actions>
        <v-card-actions v-else>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="dlg = false">
            {{ $t("releaseNotes.popup.gotIt") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </DefaultLayout>
</template>

<script>
// @ is an alias to /src
import DefaultLayout from "@/layouts/default.vue";
import { mapGetters } from "vuex";

import rl from "@/helpers/versions.json";

export default {
  name: "home",
  data: function() {
    return {
      panel: 0,
      rl: rl,
      crl: rl[0],
      dlg: true,
    };
  },
  computed: {
    ...mapGetters(["user"]),
  },
  components: {
    DefaultLayout,
  },

  created() {
    console.log("Vers", this.user.profile.seenVersion, this.crl.num);

    if (this.user.profile.seenVersion) {
      this.dlg = this.user.profile.seenVersion < this.crl.num;
    } else {
      this.dlg = true;
    }
    if (this.dlg) {
      this.updateProfile();
    }
  },

  methods: {
    goToReturnUrl: function() {
      this.$router.push(this.$route.query.returnurl);
    },
    updateProfile: function() {
      let data = this.user;
      data.profile.seenVersion = this.crl.num;
      this.$store
        .dispatch("setUser", data)
        .then(() => {
          this.$store.dispatch("showSnackMessage", { message: "Data saved" });
        })
        .catch((e) => {
          this.$store.dispatch("showSnackMessage", { message: e });
        });
    },
  },
};
</script>
